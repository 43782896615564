import React from "react";
import Link from "next/link";

import { H1, H3 } from "../components/Styling";

export default function Custom404() {
  return (
    <>
      <div>
        <H1 title="Page Not Found" />
        <Link href="/">
          <a>
            <H3 title="< Back home" />
          </a>
        </Link>
      </div>

      <style global jsx>{`
        a {
          color: blue;
          text-decoration: underline;
        }
      `}</style>

      <style jsx>{`
        div {
          height: 100vh;
          display: flex;
          flex-direction: column;
          gap: 3em;
          justify-content: center;
          align-items: center;
        }

        h1 {
          font-size: 2em;
        }

        h3 {
          font-size: 1.5em;
        }
      `}</style>
    </>
  );
}
